<div class="app-container">
  <!-- Sidebar -->
  <div class="sidebar">
    <div class="skill-activity-container">
      <div class="header h4">
        <h2>Recent activity for {{ skill?.name }}</h2>
        <button class="close-button" (click)="closeSidebar()">×</button>
      </div>
      <div class="filter-sec">
        <ng-select *ngIf="sources.length"
          class="filter-button"
          [(ngModel)]="selectedSource"
          [closeOnSelect]="false"
          [multiple]="true"
          [appendTo]="'body'"
          [clearable]="false"
          (ngModelChange)="onSourcesChange()"
          >
          <ng-option *ngFor="let source of sources" [value]="source.id">
            {{source.label}}
          </ng-option>
        </ng-select>
        <div class="sort-button d-flex justify-content-end align-items-center gap-1 clickable fs-12" (click)="toggleSortOrder()">
          <mat-icon class="fs-16 icon">sort</mat-icon>
          {{sortOrder === 0 ? 'BY NEWEST FIRST' : 'BY OLDEST FIRST'}}
        </div>
      </div>
      <div class="no-data-sec ml-3 mt-2" *ngIf="isLoading">
        Loading...
      </div>
      <div class="main-container-timeline" *ngFor="let group of groupedActivities; let i = index">
        <div class="timeline position-relative">
          <div class="timeline-bar outer" [ngClass]="{'first': i === 0}">
          </div>
          <div class="timeline-background-bar"></div>
          <div *ngFor="let activity of group.activities; let j = index; let last = last;" class="timeline-item">
            <div class="timeline-entry d-flex" [ngClass]="{'increase-height': group.activities.length > 1 && j < group.activities.length - 1 }" *ngIf="j === 0 || group.showAll">
                <div class="time">
                  <div class="time-sec">
                    {{ activity.timestamp | date: 'hh:mm a' }}<br />
                  </div>
                  <div class="date-sec">
                    {{ activity.timestamp | date: 'dd MMM yyyy' }}
                  </div>
                </div>
                <div class="timeline-bar">
                  <div class="circle" *ngIf="activity"></div>
                </div>
                <div class="icon item-center mx-3">
                  <div *ngIf="(activity?.event === 'Endorsement' && activity?.endorsements?.type !== 'approve'); else elseBlock">
                    <app-svg-icon height="20" width="20"  class="custom-svg" [src]="endorsementIconsMap[activity?.endorsements?.type]" fill="var(--color-black)"></app-svg-icon>
                  </div>
                  <ng-template #elseBlock> 
                    <mat-icon class="fs-20 mt-1 ml-1">{{iconMap[activity?.event]}}</mat-icon>
                  </ng-template>
                </div>
                <div class="content">
                  <span class="event">
                    <span class="event-name">{{ getDisplayText(activity) }}</span>
                    <span *ngIf="activity.event === 'Endorsement'">
                      by {{ activity.endorsements.actionByName }}
                    </span>
                    <span *ngIf="activity.event !== 'Endorsement'">
                      by {{ activity.label}}
                    </span>
                  </span>
                  <span *ngIf="!activity.endorsements">
                    {{ getDisplaySupportText(activity) }} {{ activity.label }}
                  </span>
                  <p class="rating mt-1 d-flex align-items-center mb-1">
                    <span class="overall-text">Overall:</span>
                    <ng-container *ngIf="!checkIfL1Endorsement(activity); else L1Endorsement">
                      <span class="star mx-2">★</span>
                      <span class="mr-1">{{ activity.overallRating?.fromValue || 0 }}</span>
                      <span class="mr-1" *ngIf="activity.overallRating?.toValue !== activity.overallRating?.fromValue">
                        → {{ activity.overallRating?.toValue || 0}}
                      </span>
                    </ng-container>
                    <mat-icon
                        *ngIf="activity.delta?.length"
                        class="expand-icon ml-2 cursor-pointer"
                        (click)="toggleDeltaVisibility(activity)"
                        [class.rotated]="activity['showDelta']">
                        expand_more
                    </mat-icon>
                  </p>
                  <ng-template #L1Endorsement>
                    <p class="rating mb-0 ml-1">
                      Final endorsement pending.
                      <app-svg-icon 
                        fill="var(--color-info-blue)" 
                        src="icons/info.svg"
                        width="12"
                        height="12"
                        class="mt-1 ml-1" 
                        matTooltip="Updated ratings are not committed to your skill profile until final endorsement is done.">
                      </app-svg-icon>
                    </p>
                  </ng-template>

                  <!-- Delta accordion -->
                  <div class="delta-accordion"
                       *ngIf="activity.delta?.length && activity['showDelta']"
                       [@expandCollapse]>
                    <div class="delta-content mb-4">
                      <div class="delta-item" *ngFor="let item of activity.delta">
                        <ng-container *ngIf="ratingKeyMap[item.key]">
                          <span class="delta-label">{{ ratingKeyMap[item.key] }}:</span>
                          <span class="star mx-2">★</span>
                          <span class="delta-value">
                            {{ item.fromValue || 0 }}
                            <ng-container *ngIf="item.toValue !== item.fromValue">
                              → {{ item.toValue || 0 }}
                            </ng-container>
                          </span>
                        </ng-container>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div>
              </div>
              <div class="show-more" *ngIf="group.activities.length > 1 && !group.showAll && j === 1">
                 <button mat-button (click)="toggleGroupVisibility(i)">
                  {{ 'Show ' + (group.activities.length - 1) + ' more ' + (group.activities.length - 1 === 1 ? 'activity' : 'activities') }}
                </button>
              </div>
          </div>
        </div>
      </div>
      <div class="pagination" *ngIf="getAllGroupedActivities().length > (currentPage * itemsPerPage)">
        <button mat-button (click)="loadMore()">Show More</button>
      </div>
    </div>
  </div>
</div>
