<div class="my-goal-cards-main-con">
  <div class="d-flex align-items-center gap-1 mb-4">
    <mat-icon class="primary">album</mat-icon>
    <span class="h4">{{label}}</span>
  </div>
  <div class="goals-card-con d-flex flex-wrap gap-3">
    <ng-container *ngFor="let data of goalsCardData; let i = index">
      <div class="p-3 gap-4 border-radius-8 goals-card">
        <div class="d-flex align-items-center">
          <mat-icon class="mr-1 goals-icon fs-24" [ngClass]="{'primary': activeTab === 0}" [ngStyle]="{ 'color': data.color }">
            {{ data.icon }}
          </mat-icon>
          <span class="h3" [ngStyle]="{ 'color': data.color }">{{ data.value }}</span>
        </div>
        <span class="mt-2 label fw-500" [ngClass]="{'primary': activeTab === 0}" [ngStyle]="{ 'color': data.color }">{{ data.label }}</span>
      </div>

      <!-- Divider only after the first card -->
      <div *ngIf="i === 0" class="divider"></div>
    </ng-container>
  </div>
</div>
