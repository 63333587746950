import { Injectable } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { SMS_OPTIONS } from 'src/app/constants';

@Injectable({
    providedIn: 'root'
})
export class SkillUtilitiesService {
    constructor(private ds: DataService) {}

    get checkIsHighDemandSkillTaggingEnabled(): boolean {
        return this.ds.client.smsSettings?.useHighDemandSkillTagging === SMS_OPTIONS.YES;
    }
}
