import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SharedDialogComponent } from '../modules/common-components/shared-dialog/shared-dialog.component';
import { DIALOG_TYPES } from '../constants';


@Injectable({
  providedIn: 'root'
})
export class WaitErrorDialogsService {
  data: any;
  dialogRef: MatDialogRef<SharedDialogComponent, any>;
  constructor(private dialog: MatDialog) { }

  showDialog(data): MatDialogRef<SharedDialogComponent, any> {
    this.data = data;

    if (!data.dontCloseAllDialogs) {
      this.closeDialog();
    }

    if (data.isApiFailureDialog && this.dialogRef?.componentInstance?.type === DIALOG_TYPES.WAIT) {
      this.closeDialog(this.dialogRef);
    }

    if (!!this.dialogRef && !this.dialogRef.componentInstance) {
      this.closeDialog(this.dialogRef);
    }

    this.dialogRef = this.dialog.open(SharedDialogComponent, { panelClass: 'shared-dialog', disableClose: data.type === 'wait' || data.dontCloseProgrammatically });
    this.dialogRef.componentInstance.data = data;
    const onEvent = this.dialogRef.componentInstance.onEvent.subscribe((data: any) => {
      if (!data.dontCloseProgrammatically) {
        this.closeDialog(this.dialogRef);
      }
    });
    this.dialogRef.afterClosed().subscribe(() => {
      onEvent.unsubscribe();
    });
    return this.dialogRef;
  }

  closeDialog(dialogRef?: MatDialogRef<SharedDialogComponent, any>) {
    if (dialogRef) {
      dialogRef.close();
    } else {
      this.dialog.openDialogs.forEach(dialogInstance => {
        !dialogInstance.componentInstance?.data?.dontCloseProgrammatically && dialogInstance.close();
      });
    }
  }
}
