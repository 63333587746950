import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss']
})
export class DoughnutChartComponent implements OnChanges {
  @Input() data: any;
  @Input() colorType = "multiColor"; //singleRed, gradientBlue, gradientGreen, gradientPurple
  @Input() size = "full"; //half
  @Input() showChange = "Right"; //Below, None, None-Below
  @Input() width = 192;
  @Input() config: any[];
  @Input() showLegend: boolean = false;
  @Input() subtext: string = '';
  @ViewChild('canvas') canvas: ElementRef;
  datasets: any = [];
  labels = [];
  colors: any[] = [];
  options: any = {
    plugins:
    {
      legend: { display: false },
      tooltip: { displayColors: false },
      datalabels: {
        display: false,
      },
    },
    layout: { padding: 5 }
  };
  percentString = '';

  private readonly COLOURS_MAP = {
    purple: this.themeService.getColorValue('--color-purple-50'),
    dark_purple: this.themeService.getColorValue('--color-purple-70'),
    cyan: this.themeService.getColorValue('--color-cyan-50'),
    magenta: this.themeService.getColorValue('--color-magenta-50'),
    dark_magenta: this.themeService.getColorValue('--color-magenta-70'),
    teal: this.themeService.getColorValue('--color-teal-50'),
    dark_teal: this.themeService.getColorValue('--color-teal-70'),
    blue: this.themeService.getColorValue('--color-blue-80'),
    light_red: this.themeService.getColorValue('--color-red-50'),
    red: this.themeService.getColorValue('--color-red-90'),
    green: this.themeService.getColorValue('--color-green-60')
  }

  constructor(private themeService: ThemeService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.setDoughnutChartData();
      this.setGradient();
    }
  }

  setDoughnutChartData() {
    switch (this.colorType) {
      case "multiColor":
        this.colors = this.config?.length > 0 ? this.config?.map(config => config?.color) :
          ['#15bcb7', '#33abfc', '#0d62fe', '#ffbe4f', '#fe872c'];
        break;
      case "singleRed":
        this.colors = ['#fbb0b2', '#ff7e84', '#f25159', '#d71f29', '#a81921'];
        break;
      case "gradientBlue":
      case "gradientGreen":
      case "gradientPurple":
      case "gradientCyan":
        this.colors = ['#0f62fe', 'rgba(141,141,148,0.28)'];
        break;
      case "onboarding2":
        this.colors = [this.COLOURS_MAP.dark_purple, this.COLOURS_MAP.teal];
        break;
      case "onboarding3":
        this.colors = [this.COLOURS_MAP.magenta, this.COLOURS_MAP.cyan, this.COLOURS_MAP.dark_purple];
        break;
      case "onboarding4":
        this.colors = [this.COLOURS_MAP.teal, this.COLOURS_MAP.blue, this.COLOURS_MAP.purple, this.COLOURS_MAP.dark_magenta];
        break;
      case "onboarding5":
        this.colors = [this.COLOURS_MAP.dark_purple, this.COLOURS_MAP.cyan, this.COLOURS_MAP.dark_teal, this.COLOURS_MAP.dark_magenta, this.COLOURS_MAP.red];
        break;
      case "onboarding6":
        this.colors = [
          this.COLOURS_MAP.dark_purple,
          this.COLOURS_MAP.cyan,
          this.COLOURS_MAP.dark_teal,
          this.COLOURS_MAP.dark_magenta,
          this.COLOURS_MAP.light_red,
          this.COLOURS_MAP.green
        ];
        break;
      case "onboarding7":
        this.colors = [
          this.COLOURS_MAP.dark_purple,
          this.COLOURS_MAP.cyan,
          this.COLOURS_MAP.dark_teal,
          this.COLOURS_MAP.dark_magenta,
          this.COLOURS_MAP.light_red,
          this.COLOURS_MAP.green,
          this.COLOURS_MAP.blue
        ];
        break;
    }
    if (this.data.noData) {
      this.colors = ['#eeeeee'];
    }
    this.datasets = [{
      cutout: this.data.cutoutPercentage || (this.size == 'half' ? '72%' : '65%'),
      data: this.data.values,
      backgroundColor: this.colors,
      hoverBackgroundColor: this.colors,
      hoverBorderColor: this.colors,
      rotation: this.size == 'half' ? -90 : 0,
      borderWidth: this.size == 'half' ? 0 : 2,
      hoverOffset: 5,
      circumference: this.size == 'half' ? 180 : 360,
    }]
    if (this.size == 'half') {
      this.options.layout = { padding: { bottom: this.width / 2, top: 5 } }
    }
    this.labels = this.data.labels || [];
    for (let i = this.labels?.length; i < this.data?.values?.length; i++)this.labels.push(' ');
    if (!this.data.labels) this.options.plugins.tooltip = { enabled: false, tooltipCaretSize: 0, mode: 'single' };
    if (this.data.showPercentValue) this.percentString = '%';
    if (this.data.tooltip) {
      this.options.plugins.tooltip = { enabled: true, displayColors: false };

      this.options.plugins.tooltip.filter = (tooltipItem) => tooltipItem.dataIndex === 0 || tooltipItem.dataIndex < this.data?.tooltips?.length;


      this.options.plugins.tooltip.callbacks = {
        label: (context): string[] => {
          let s = [], pieces = (this.data.tooltips ? this.data.tooltips[context.dataIndex] : this.data.tooltip).replace(/\s+/g, ' ').split(/\s/g);
          for (let i = 0; i < pieces.length; i++) {
            let ts = '';
            for (let j = i; j < pieces.length; j++) {
              ts += (ts.length ? ' ' : '') + pieces[j];
              if (ts.length > 12) {
                break;
              }
              i++;
            }
            s.push(ts);
          }
          return (s);
        },
      };
    }
  }

  setGradient(): void {
    setTimeout(() => {
      if (!this.canvas) return;
      if (this.colorType.search('gradient') >= 0) {
        let gradient = this.canvas.nativeElement.getContext('2d').createLinearGradient(0, 0, this.width / (this.data.value < 0.25 ? 3 : this.data.value < 0.5 ? 2 : this.data.value < 0.75 ? 1.5 : 1), 0);
        if (this.colorType == 'gradientBlue') {
          gradient.addColorStop(0, '#0356f0');
          gradient.addColorStop(1, '#33b1ff');
        } else if (this.colorType == 'gradientPurple') {
          gradient.addColorStop(0, '#ee5396');
          gradient.addColorStop(0.3, '#6f7fe9');
          gradient.addColorStop(1, '#4589ff');
        } else if (this.colorType == 'gradientGreen') {
          gradient.addColorStop(0, '#007d79');
          gradient.addColorStop(0.25, '#25a46c');
          gradient.addColorStop(1, '#5be782');
        } else if (this.colorType == "gradientCyan") {
          gradient.addColorStop(0, '#1192e8');
          gradient.addColorStop(0.5, '#007d79');
          gradient.addColorStop(1, '#1192e8');
        }
        this.datasets[0].backgroundColor = [gradient, '#f2f2f2'];
        this.datasets[0].hoverBackgroundColor = [gradient, '#f2f2f2'];
        this.datasets = [...this.datasets];
      }
    }, 200 + Math.random() * 100);

  }
}
