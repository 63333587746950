import { Component, EventEmitter, Output, AfterViewChecked, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IGoal } from 'src/app/modules/admin/admin-goal-management/components/goals';

@Component({
  selector: 'app-goals-check-in',
  templateUrl: './goals-check-in.component.html',
  styleUrls: ['./goals-check-in.component.scss']
})
export class GoalsCheckInComponent implements AfterViewChecked {

  @ViewChild('numberInput') numberInput: ElementRef;
  @Output() onCheckIn = new EventEmitter();

  public data: IGoal;
  public selectedStatus: string = 'IN_COMPLETE';
  public showInput: boolean = false;
  public currentValue: number;

  public checkInForm: FormGroup;
  private readonly CUSTOM: string = 'custom';

  //------------------------------------------------------------------
  // Constructor
  //------------------------------------------------------------------

  constructor(private fb: FormBuilder) {}

  //------------------------------------------------------------------
  // Lifecycle Hooks
  //------------------------------------------------------------------

  ngOnInit(): void {
    this.checkInForm = this.fb.group({
      currentValue: [this.data?.currentValue || 0, [Validators.min(0)]],
      progressDescription: ['', Validators.maxLength(1000)],
      selectedStatus: ['IN_COMPLETE']
    });
  }

  ngAfterViewChecked(): void {
    if (this.showInput && this.numberInput) {
      this.numberInput.nativeElement.focus();
    }
  }

  //------------------------------------------------------------------
  // Event Handlers
  //------------------------------------------------------------------

  get currencyControl() {
    return this.checkInForm.get('currentValue');
  }

  get progressDescriptionControl() {
    return this.checkInForm.get('progressDescription');
  }

  get infoTip(): string {
    return this.data.type?.toLowerCase() !== this.CUSTOM ? 'Adding progress notes here will show up as comments on the goal details page.' : 
      'Adding progress notes here will show up as comments on the goal details page. The goals can be marked as complete by updating the status here.';
  }

  public onCheckInClick() {
    if (this.data?.type?.toLowerCase() === 'custom' && this.data.measurement !== 'COMPLETE_INCOMPLETE') {
      this.data.currentValue = JSON.stringify(this.currencyControl?.value || 0);
    }

    const formData = {
      progressDescription: this.checkInForm?.value?.progressDescription || '',
      ...(this.data.measurement === 'COMPLETE_INCOMPLETE'
        ? { progressStatus: this.selectedStatus === 'INCOMPLETE' ? 'IN_COMPLETE' : this.selectedStatus }
        : { currentValue: JSON.stringify(this.checkInForm.value.currentValue) }),
    };
  
    this.onCheckIn.emit({event: 'checkIn', data: this.data, formData });
  }

  public getCurrencySymbol(measurement: string): string {
    switch (measurement) {
      case 'CURRENCY_INR':
        return '₹';
      case 'CURRENCY_USD':
        return '$';
      case 'CURRENCY_EUR':
        return '€';
      default:
        return '';
    }
  }

  public selectStatus(status: string) {
    this.selectedStatus = status;
  }

  public showNumberInput() {
    this.showInput = true;
  }

  public hideNumberInput() {
    this.showInput = false;
  }
}
