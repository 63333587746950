import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { BUTTON_EVENTS, DIALOG_TYPES, RESPONSE_STATUS, SMS_OPTIONS } from 'src/app/constants';
import { DataService } from 'src/app/services/data.service';
import { CommonCalculationsService } from 'src/app/services/common-calculations.service';
import { SkillDetailsModalComponent } from '../../modals/skill-details-modal/skill-details-modal.component';
import { SharedDialogComponent } from 'src/app/modules/common-components/shared-dialog/shared-dialog.component';
import { PrismSearchService } from 'src/app/modules/prism-search/services/prism-search.service';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';
import { API_ENDPOINT } from 'src/app/constants/api-endpoint.constants';
import { SkillUtilitiesService } from '../../../services/skill-utilities.service';

export enum ProficiencySliderMode {
  GENERAL = 'general',
  ADD = 'add',
  EDIT = 'edit',
}
@Component({
  selector: 'app-proficiency-slider',
  templateUrl: './proficiency-slider.component.html',
  styleUrls: ['./proficiency-slider.component.scss']
})
export class ProficiencySliderComponent implements OnInit, AfterViewInit {

  @Input() data: any;
  @Input() mode: string = ProficiencySliderMode.GENERAL
  @Output() onRemove = new EventEmitter();

  public skillForm: FormGroup;
  public settings: any;
  public maxRating: number = 5;
  public proficiencyLevels = [];
  public skillQuestions = [
    { key: "incomingRating", title: "Incoming Rating", type: "Rating", editable: true, order: 1 },
    { key: "aspirationalRating", title: "Aspirational", type: "Rating", editable: true, order: 2 },
    { key: "experience", title: "Experience (yrs)", type: "Experience", form: true, validators: [Validators.min(0.5), Validators.max(30)], placeholder: 'Experience', options: this.getExperienceOptions(), order: 3 },
    { key: "lastUsed", title: "Last Used", type: "Year", form: true, validators: [Validators.min(1950), Validators.max(new Date().getFullYear())], placeholder: 'Year', options: Array.from({ length: 50 }, (_, i) => new Date().getFullYear() - i), order: 4 },
    { key: "skillTypeId", title: this.ds.dictionary.skillType, type: "Tagging", form: true, validators: [], placeholder: 'None', order: 5 },
  ];
  public proficiencySliderMode = ProficiencySliderMode;

  constructor(
    public ds: DataService,
    private skillUtils: SkillUtilitiesService,
    private fb: FormBuilder,
    private commonCalculations: CommonCalculationsService,
    private dialog: MatDialog,
    private prismSearchService: PrismSearchService,
    private weds: WaitErrorDialogsService,
  ) { }

  ngOnInit() {
    this.initializeSettings();
    this.updateQuestionsForSettings();
    this.createSkillForm();
  }

  ngAfterViewInit(): void {
    this.updateSliderProperties();
    this.updateTicks();
    this.skillQuestions.forEach(q => {
      if (q.type === 'Rating') {
        this.updateProficiency(q.key);
      }
    })
  }

  get isHighDemandSkillEnabled(): boolean {
    return this.skillUtils.checkIsHighDemandSkillTaggingEnabled;
  }

  private initializeSettings() {
    const smsSettings = this.ds.client.smsSettings;
    const skillRating = smsSettings.skillRating;
    if (skillRating === 'Stars 1-5') {
      for (let i = 1; i <= 5; i++) {
        this.maxRating = 5;
        this.proficiencyLevels.push(smsSettings[`skillToolTipLabel${i}`])
      }
    } else {
      for (let i = 1; i <= 4; i++) {
        this.maxRating = 4;
        this.proficiencyLevels.push(smsSettings[`skillToolTipLabel${i}`])
      }
    }
    this.settings = this.ds.client.smsSettings.categoryWiseSettings.find(
      (item: any) => item.categoryId == this.data.skillCategory.categoryId
    );
  }

  private updateQuestionsForSettings() {
    for (let i = 0; i < this.skillQuestions.length; i++) {
      let q = this.skillQuestions[i];
      if (q.type == 'Rating' && !this.settings.skillRatings.filter((rating) => rating.useRating == 'Yes' && rating.dataKey == q.key)[0]) { 
        this.skillQuestions.splice(i--, 1) 
      }
      if (q.key == 'experience' && this.settings.collectExperience != 'Yes') {
        this.skillQuestions.splice(i--, 1);
      }
      if (q.key == 'lastUsed' && this.settings.collectSkillLastUsed != 'Yes') {
        this.skillQuestions.splice(i--, 1);
      }
      if (q.key == 'skillTypeId' && this.settings.enablePrimarySkill != 'Yes') {
        this.skillQuestions.splice(i--, 1);
      }
      else if (q.key == 'skillTypeId') {
        q.options = this.settings.skillTypes || [];
      }
    }

    this.skillQuestions.sort((a, b) => (a.order - b.order));
  }

  private updateSliderProperties() {
    const ratingQuestions = this.skillQuestions.filter(item => item.type === 'Rating');
    ratingQuestions.forEach(item => {
      const slider = document.querySelector(`.proficiency-slider-range_${item.key}_${this.data.skillItemId}`) as HTMLElement;
      slider.style.setProperty('--max-rating', this.maxRating.toString());
    });
  }

  public updateProficiency(key: string) {
    const slider = document.querySelector(`.proficiency-slider-range_${key}_${this.data.skillItemId}`) as HTMLElement;
    slider.style.setProperty('--value', this.getFormControlValue(key).toString());
    this.data[key] = this.getFormControlValue(key).toString();
    this.commonCalculations.calculateOverallRating(this.settings.skillRatings, this.data);
    this.updateTicks();
  }

  public getFormControlValue(controlName: string): number {
    return this.skillForm.get(controlName)?.value || 1;
  }

  get isHighDemandSkill(): boolean {
    return this.data.data ? this.data.data?.isHighDemandSkill === SMS_OPTIONS.YES : this.data?.isHighDemandSkill === SMS_OPTIONS.YES;
  }

  public showSkillOverlayDetails(): void {
    const dialogRef = this.dialog.open(SkillDetailsModalComponent, {
      width: (600 > (0.65 * window.innerWidth) ? (0.65 * window.innerWidth) : 600) + 'px',
      height: (0.65 * window.innerHeight) + 'px',
      panelClass: 'resume-builder'
    });
    dialogRef.componentInstance.skillData = this.data;
    dialogRef.componentInstance.onEvent.pipe(take(1)).subscribe((data: any) => {
      if (data.status === 'close') {
        dialogRef.close();
      }
    });
  }

  public showRemoveConfirmationModal(): void {
    const dialogRef = this.dialog.open(SharedDialogComponent, { panelClass: 'shared-dialog', disableClose: false });
    dialogRef.componentInstance.data = {
      type: DIALOG_TYPES.GENERIC,
      title: 'Remove skill?',
      message: 'Are you sure you want to remove this skill?',
      btnTitle: 'Remove Skill',
      btn2Title: 'Cancel'
    };
    const onEvent = dialogRef.componentInstance.onEvent.subscribe((data: any) => {
      if (data.event === BUTTON_EVENTS.BUTTON_CLICK) {
        dialogRef.close();
        this.onRemove.emit(this.data);
      } else if (data.event === BUTTON_EVENTS.BUTTON2_CLICK) {
        dialogRef.close();
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      onEvent.unsubscribe();
    });
  }

  private getExperienceOptions() {
    const options = [
      { name: '< 1 Year', value: 0.5 },
      { name: '1 Year', value: 1 },
    ];

    for (let i = 2; i <= 30; i++) {
      options.push({ name: `${i} Years`, value: i });
    }

    options.push({ name: '> 30 Years', value: 30 });
    return options;
  }

  private createSkillForm() {
    this.skillForm = this.fb.group({});
    this.addControlForSkillItem(this.data);
    this.setDefaultsForAllowSaveSkillsWOMandatory();
  }

  private addControlForSkillItem(skillItem) {
    this.skillQuestions.forEach((q) => {
      let value = '';
      
      if (q.type === 'Rating' && skillItem.recommendedValues?.rating) {
        value = skillItem.recommendedValues.rating.toString();
      }
      else if (skillItem.recommendedValues && skillItem.recommendedValues[q.key]) {
        value = skillItem.recommendedValues[q.key];
      } 
      else if (skillItem.existingValues && skillItem.existingValues[q.key]) {
        value = skillItem.existingValues[q.key];
      }
      else if (q.type === 'Rating') {
        value = '1';
      }

      this.skillForm.addControl(q.key, new FormControl(value, q.validators));
    });
  }

  private setDefaultsForAllowSaveSkillsWOMandatory() {
    const experienceControl = this.skillForm.get('experience');
    if (experienceControl && !experienceControl.value) {
      experienceControl.setValue(this.getExperienceOptions()[0].value, { onlySelf: true, emitEvent: false });
    }

    const lastUsedControl = this.skillForm.get('lastUsed');
    if (lastUsedControl && !lastUsedControl.value) {
      lastUsedControl.setValue(new Date().getFullYear(), { onlySelf: true, emitEvent: false });
    }

    const skillTypeIdControl = this.skillForm.get('skillTypeId');
    if (skillTypeIdControl && !skillTypeIdControl.value) {
      skillTypeIdControl.setValue(this.settings.skillTypes[0].skillTypeId, { onlySelf: true, emitEvent: false });
    }
  }

  private updateTicks(): void {
    const ratingQuestions = this.skillQuestions.filter(item => item.type === 'Rating');
    ratingQuestions.forEach(item => {
      const value = this.getFormControlValue(item.key);
      const ticks = document.querySelectorAll(`.proficiency-slider-range_${item.key}_${this.data.skillItemId} ~ .proficiency-slider-ticks .tick`);
      ticks.forEach((tick, index) => {
        tick.classList.toggle('active', index + 1 < Number(value));
        tick.classList.toggle('hidden', index + 1 === Number(value));
      });
    });
  }

  public onAddToMySkills(){
    this.data = {...this.data, ...this.skillForm.value, updateSource: ''};
    this.prismSearchService.getExistingUserSkills().pipe(take(1)).subscribe((existingSkills: any) => {
      let data: any = { userId: this.ds.currentUserData.userId, type: 'saveMySkills', skillItems: [...existingSkills,this.data] };
      this.save(data);
    })
  }

  public onUpdate(){
    this.prismSearchService.getExistingUserSkills().pipe(take(1)).subscribe((existingSkills: any) => {
      const index = existingSkills.findIndex((skill) => skill.skillItemId === this.data.skillItemId);
      existingSkills[index] = {...existingSkills[index], ...this.skillForm.value};
      this.save({ userId: this.ds.currentUserData.userId, type: 'saveMySkills', skillItems: existingSkills, updateSource: '' });
    })
  }

  public onDelete(){
    this.prismSearchService.getExistingUserSkills().pipe(take(1)).subscribe((existingSkills: any) => {
      const skillItems = existingSkills.filter((skill) => skill.skillItemId !== this.data.skillItemId);
      this.save({ userId: this.ds.currentUserData.userId, type: 'saveMySkills', updateSource: '', skillItems: skillItems, deletedSkillItems: [this.data] });
    })
  }

  private save(payload: any){
    this.weds.showDialog({ type: DIALOG_TYPES.WAIT, code: -5, dontCloseAllDialogs: true });
    this.ds.saveData(API_ENDPOINT.SAVE_MY_SKILLS, payload).pipe(take(1)).subscribe((rs: any) => {
      this.weds.closeDialog();
      if (rs.status === RESPONSE_STATUS.SUCCESS) {
        this.prismSearchService.setRefreshAPI();
      } else {
        this.weds.showDialog({ type: DIALOG_TYPES.GENERIC, code: rs.error ? rs.error.code : 99 });
      }
    })
  }
}
