const RULE_RESTRICTIONS = {
  ALL_VALUES: 'ALL_VALUES',
  NULL: 'NULL',
  CUSTOM: 'CUSTOM',
  USER_BASED: 'USER_BASED',
};

export const tagFilterValueArray = [
  { id: RULE_RESTRICTIONS.USER_BASED, value: "Restrict to User's Value" },
  { id: RULE_RESTRICTIONS.ALL_VALUES, value: 'All Values' },
  { id: RULE_RESTRICTIONS.CUSTOM, value: 'Specific Values' },
  { id: RULE_RESTRICTIONS.NULL, value: 'NULL Values' },
];

export const TAG_FILTER_ENABLED_MODALS = {
  MANAGE_LEARNINGS: 'Manage Learnings',
  SPECIALIZATION_REQUESTS: 'Specialization Requests',
}

export const ACCESS_LEVELS = {
  NO_ACCESS: 'No Access',
  VIEW_ONLY: 'View Only',
  EDIT: 'Edit',
};

export const BULK_ENDORSE_REJECT_SKILLS = {
  NO: 'No',
  AT_ASSOCIATE_LEVEL: 'At associate level',
  FOR_ALL_THE_REQUESTS: 'For all the requests',
};

export const ALIASES_DB_KEYS = {
  SERVICES: 'servicesAlias',
  SKILL_CLUSTERS: 'skillClusterAlias',
  MANDATORY_SKILLS: 'mandatoryAlias',
  CORE_MANDATORY_SKILLS: 'coreMandatoryAlias',
  OPTIONAL_SKILLS: 'optionalAlias',
  CONDITIONAL_MANDATORY_SKILLS: 'conditionalMandatoryAlias',
  DEMAND_UNITS: 'demandUnitAlias',
  DESIGNATION: 'designationAlias',
};
export const DEFAULT_ALIASES = {
  SERVICES: 'Service',
  SKILL_CLUSTERS: 'Skill Cluster',
  LEAF_SKILLS: 'Skill',
  MANDATORY_SKILLS: 'Mandatory',
  CORE_MANDATORY_SKILLS: 'Core Mandatory',
  OPTIONAL_SKILLS: 'Optional',
  CONDITIONAL_MANDATORY_SKILLS: 'Condtional Mandatory',
  DEMAND_UNITS: 'Demand Unit',
  DESIGNATION: 'Designation',
  PRACTICE: 'Practice',
};
export const SMS_OPTIONS = {
  YES: 'Yes',
  NO: 'No',
  SKILL_CLUSTER_BASED: 'Skill Cluster Based',
  MANAGE_LEARNINGS: 'Manage Learnings',
  SPECIALIZATION_REQUESTS: 'Specialization Requests',
  STANDARD: 'Standard',
  DESIGNATION: 'Designation',
  DESIGNATION_LEVEL: 'Designation Level',
  MATCH_SCORE: 'Match Score',
  BINARY: 'Binary',
  PRE_REQUISITE_SPECIALIZATIONS: 'Pre-requisite Specializations',
  SPECIALIZATION_PROGRAM: 'Specialization Program',
  LEVEL_OF_SPECIALIZATION: 'Level Of Specialization',
  QUALIFICATION_RATING_TYPES: 'Qualification Rating Types',
  QUALIFICATION_RATING: 'Qualification Rating',
  EXIT_RATING_TYPES: 'Exit Rating Types',
  ASSIGNEE: 'Assignee',
  ASSIGNER: 'Assigner',
  PRIMARY: 'Primary',
  SECONDARY: 'Secondary',
  STARS4: 'Stars 1-4',
  TIER_MATCHING_LOGIC_OPTIONS: {
    asPerTierType: "As Per Tier Type",
    bothDesignationAndDesignationLevel: "Both Designation and Designation Level"
  },
  STARS_4: 'Stars 1-4'
}

export const TEMPLATE_TYPE = {
  ANY: 'any',
  STATIC: 'static',
  DYNAMIC: 'dynamic'
}

export const DEMAND_VALUE_OPTIONS = ['Top 40%', 'Mid 40%', 'Bottom 20%', 'No Demand']
export const RESPONSE_STATUS = {
  FAIL: "Fail",
  SUCCESS: "Success",
  REDIRECT: "Redirect",
  PENDING: "Pending"
}

export const RESUME_FORMAT_LIST = ['pdf', 'docx', 'jpg', 'jpeg', 'png', 'pptx']

export const LE_PATH_CSV_HEADERS = [
  { key: 'SkillItemId', required: true},
  { key: 'Status', required: true},
  { key: 'LevelPk', required: true},
  { key: 'LearningElementPk', required: true},
  { key: 'IsFinished', required: false},
  { key: 'Priority', required: true},
  { key: 'OptionalGroupId', required: false}
]
export const LE_MASTER_CSV_HEADERS = [
  { key: 'LearningElementId', required: true },
  { key: 'Title', required: true },
  { key: 'Status', required: false },
  { key: 'Description', required: false },
  { key: 'Source', required: true },
  { key: 'Type', required: true },
  { key: 'SubType', required: false },
  { key: 'ThumbnailUrl', required: false },
  { key: 'ContentUrl', required: false },
  { key: 'OriginCreator', required: false },
  { key: 'Duration', required: false },
  { key: 'CompletionTimeline', required: false }
];

export const HOTJAR_MENU_ITEMS = [
  'sms/mySkills',
  'sms/mySpecializations',
  'sms/courses',
  'sms/projects',
  'sms/myTeam',
  'sms/manageLearnings',
  'sms/teamSkillEndorsements',
  'sms/github',
  'sms/stackoverflow',
  'sms/socialconnect'
];

export const HOTJAR_EVENTS = {
  // Menu Items
  'sms/mySkills': 'sms_module_my_skills',
  'sms/mySpecializations': 'sms_module_my_specializations',
  'sms/courses': 'sms_module_my_courses',
  'sms/projects': 'sms_module_my_projects',
  'sms/myTeam': 'sms_module_team_details',
  'sms/manageLearnings': 'sms_module_manage_learnings',
  'sms/teamSkillEndorsements': 'sms_module_skill_approvals',
  'sms/github': 'sms_module_my_github',
  'sms/stackoverflow': 'sms_module_my_stackoverflow',
  'sms/socialconnect': 'sms_module_my_circle',

  // Actions
  ONBOARDING_RESUME_UPLOAD: 'sms_onboarding_resume_upload',
  ONBOARDING_ADD_SKILLS_MANUALLY: 'sms_onboarding_add_skills_manually',
  ONBOARDING_BROWSE_SKILLS:'sms_onboarding_browse_skills_modal_opens',
  ONBOARDING_SEARCH_BOX:'sms_onboarding_clicks_add_skills_search_box',
  ONBOARDING_REVIEW_NOW:'sms_onboarding_review_duplicate_skills_modal_opens',
  ONBOARDING_COMPLETE: 'sms_onboarding_complete',
  ADD_SKILLS: 'sms_module_my_skills_opens_browse_skills_modal',
  UPLOAD_RESUME: 'sms_module_my_skills_add_skills_via_resume',
  SEARCH_BOX: 'sms_module_my_skills_add_skills_via_searchbox',
  PROFILE_NAME_CLICK: 'sms_module_my_profile',
  AUTO_PROFILE_UPDATE: 'sms_module_my_profile_auto_profile_update',
  UPDATE_EXTRACTION_FAILURE: 'sms_module_my_profile_auto_profile_update_extraction_failure',
  UPDATE_SUCCESS: 'sms_module_my_profile_auto_profile_update_success',
  GLOBAL_USER_SEARCH: 'sms_module_global_user_search',
  GLOBAL_USER_SEARCH_VIEW_USERS: 'sms_module_global_user_search_view_users',
  VIEW_NOTIFICATIONS: 'sms_module_notifications',
  PROFILE_PAGE_RESUME_BUTTON: 'sms_resumes',
  PROFILE_PAGE_SAVE: 'sms_profile_page_save',
  RESUME_MODAL_BUILT_FROM_PROFILE: 'sms_resumes_builtFromProfile',
  TEAM_DETAILS_RESUME: 'sms_teamDetails_resumes'
}

export const CUSTOM_MESSAGES = {
  LINKED_PARSING_ERROR: "Error while parsing LinkedIn profile data",
  MISSING_LEARNING_PATH: "No Learning path configured. Please reach out to your admin to enable the same",
  INVALID_FILE_SIZE: "Invalid File Size"
}

export enum BUTTON_EVENTS {
  BUTTON_CLICK = 'BTN_CLICK',
  BUTTON2_CLICK = 'BTN2_CLICK'
}

export enum DIALOG_TYPES {
  GENERIC = 'generic',
  WAIT    = 'wait'
}

export const THEME_KEYS = [
  'primaryColorLightest',
  'primaryColorLight',
  'primaryColor',
  'primaryColorDark',
  'accentColor',
  'accentColorDark',
]
