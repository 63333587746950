<div class="proficiency-slider-wrapper">
  <div class="proficiency-slider-header">
    <div class="item-center gap-1">
      <mat-icon *ngIf="isHighDemandSkill && isHighDemandSkillEnabled" class="proficiency-slider-header-high-demand-skill-icon">trending_up</mat-icon>
      <span>{{data.skillName}}</span>
    </div>
    <div *ngIf="mode === proficiencySliderMode.GENERAL" class="proficiency-slider-header-actions">
      <div (click)="showSkillOverlayDetails()" class="proficiency-slider-header-actions-info">
        <app-svg-icon src="icons/info.svg" class="mt-1" viewBox="0 0 14 14" fill="var(--color-info-blue)">
        </app-svg-icon>
        Details
      </div>
      <button (click)="showRemoveConfirmationModal()" class="mt-n1" mat-icon-button>
        <mat-icon class="proficiency-slider-header-actions-close">close</mat-icon>
      </button>
    </div>
  </div>
  <div class="proficiency-slider-body">
    <div class="proficiency-slider-container">
      <div class="proficiency-slider-container-data">
        <form class="d-flex flex-wrap gap-2 w-100 justify-content-between" [formGroup]="skillForm">
          <div class="d-flex" *ngFor="let item of skillQuestions"
            [ngClass]="item.type === 'Rating' ? 'w-100 flex-column' : 'flex-row flex-grow-3'">
            <ng-container *ngIf="item.type === 'Rating'">
              <div class="d-flex justify-content-between">
                <div>{{item.title}}</div>
                <div class="d-flex gap-2">
                  <app-svg-icon class="mt-2px" height="16px" width="16px" viewBox="0 0 25 24"
                    src="icons/skill-star.svg"></app-svg-icon>
                  {{ getFormControlValue(item.key) }} {{ proficiencyLevels[getFormControlValue(item.key) - 1] }}
                </div>
              </div>
              <div>
                <input class="proficiency-slider-range_{{item.key}}_{{data.skillItemId}}" type="range" min="1"
                  max="{{maxRating}}" step="1" formControlName="{{item.key}}" (change)="updateProficiency(item.key)">
                <div class="proficiency-slider-ticks">
                  <span class="tick" *ngFor="let level of proficiencyLevels; let i = index" [ngClass]="{
                    'active': i + 1 < getFormControlValue(item.key),
                    'hidden': i + 1 == getFormControlValue(item.key)
                  }"></span>
                </div>
              </div>
            </ng-container>
            <div class="d-flex flex-column flex-grow-1 gap-1" *ngIf="item.type === 'Experience'">
              <mat-form-field appearance="outline" matTooltip="Enter your skill experience in years">
                <mat-label>{{item.title}}</mat-label>
                <mat-select formControlName="{{item.key}}"
                  placeholder="{{item.placeholder}}">
                  <mat-option *ngFor="let option of item.options" [value]="option.value">
                    {{option.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="d-flex flex-column flex-grow-1 gap-1" *ngIf="item.type === 'Year'">
              <mat-form-field matTooltip="Last time you used the skill" appearance="outline">
                <mat-label>{{item.title}}</mat-label>
                <mat-select formControlName="{{item.key}}"
                  placeholder="{{item.placeholder}}">
                  <mat-option *ngFor="let year of item.options" [value]="year">{{year}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="d-flex flex-column flex-grow-1 gap-1" *ngIf="item.type === 'Tagging'">
              <mat-form-field appearance="outline" matTooltip="Choose your {{ds.dictionary.skillType}}">
                <mat-label>{{item.title}}</mat-label>
                <mat-select formControlName="{{item.key}}"
                  placeholder="{{item.placeholder}}">
                  <mat-option *ngFor="let option of item.options" [value]="option.skillTypeId">
                    {{option.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- Footer action buttons -->
    <div *ngIf="mode!=proficiencySliderMode.GENERAL" class="proficiency-slider-footer pt-3">
      <button *ngIf="mode === proficiencySliderMode.ADD" (click)="onAddToMySkills()" mat-stroked-button class="primary-button w-100">
        <mat-icon class="fs-16 h-16px w-16px white mr-1">add</mat-icon>
        <span>ADD TO MY SKILLS</span>
      </button>
      <div *ngIf="mode === proficiencySliderMode.EDIT" class="d-flex justify-content-between">
        <button (click)="onDelete()" class="secondary-button-warn delete-btn" mat-stroked-button>
          <mat-icon class="fs-16 mb-1px mr-1">close</mat-icon>
          <span>DELETE</span>
        </button>
        <button (click)="onUpdate()" class="primary-button"  mat-stroked-button>
          SAVE
        </button>
      </div>
    </div>
  </div>
</div>
