import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent implements OnInit {

  @Input() userImageUrl: string | null = null;
  @Input() userInitials: string = '';
  @Input() width: number = 48;
  @Input() height: number = 48;

  //------------------------------------------------------------------
  // Constructor
  //------------------------------------------------------------------

  constructor() { }

  //------------------------------------------------------------------
  // Lifecycle Hooks
  //------------------------------------------------------------------

  ngOnInit(): void {
  }

  get avatarStyle() {
    return {
      width: `${this.width}px`,
      height: `${this.height}px`,
      fontSize: `${this.height * 0.375}px`
    };
  }

}
