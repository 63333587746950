import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-paginator-ui',
  templateUrl: './paginator-ui.component.html',
  styleUrls: ['./paginator-ui.component.scss']
})
export class PaginatorUiComponent implements OnInit {
  @Output() onPaginationEvent = new EventEmitter();
  @Input() data: any;
  @Input() itemTitle: string = "";
  limit = 10;
  pageNumber = 1;
  numPages = [1, 2, 3];
  numPageOptions = [5, 10, 20, 50, 100];
  endOfRange = 0;
  startOfRange = 0;
  constructor(private ds: DataService) { }

  ngOnInit(): void {
    this.ds.offsetUpdated.subscribe(offset => {
      this.pageNumber = ( offset / this.limit ) + 1;
    })
  }

  //Bottom bar
  calculatePageNumbers(data?) {
    if (data) this.data = data;
    this.data.totalDataCnt = Number(this.data.totalDataCnt) || 0;
    const n = this.pageNumber;
    this.numPages = [n];
    if (n > 1) this.numPages.splice(0, 0, n - 1);
    if (n + 1 <= Math.ceil(this.data.totalDataCnt / this.limit)) this.numPages.push(n + 1);
    if (this.numPages.length < 3 && n + 2 <= Math.ceil(this.data.totalDataCnt / this.limit)) this.numPages.push(n + 2);
    else if (this.numPages.length < 3 && n > 2) this.numPages.splice(0, 0, n - 2);
    this.endOfRange = Math.min(this.pageNumber * this.limit, this.data.totalDataCnt);
    this.startOfRange = Math.max((this.pageNumber-1) * this.limit + 1, 1);
  }
  onLimitChange(event) {
    this.pageNumber = 1;
    this.limit = event.value;
    this.ds.updateLimit();
    this.ds.updateOffset(0);
    this.onPaginationEvent.emit({ type: 'getData' });
  }
  onPageNumberClick(n) {
    if (this.pageNumber == n) return;
    this.pageNumber = n;
    this.ds.updatePageNumber(this.pageNumber);
    this.onPaginationEvent.emit({ type: 'getData' });
  }
  onNextPrev(type) {
    if (type == 'first') this.pageNumber = 1;
    if (type == 'next') this.pageNumber++;
    if (type == 'prev') this.pageNumber--;
    if (type == 'last') this.pageNumber = Math.ceil(this.data.totalDataCnt / this.limit);
    this.ds.updatePageNumber(this.pageNumber);
    this.onPaginationEvent.emit({ type: 'getData' });
  }
}
