
<button #starButton (menuOpened)="onMenuOpen()" class="proficiency-star-container" [ngClass]="editable?'editable':'not-editable'" [matMenuTriggerFor]="menu"
    [disabled]="!editable && !showRequiredProficiency"
    [matTooltip]="hideRatingValue ? 'Rating: '+(skillItem[key]||0)||0: (editable ? 'Click to edit' : '')" matTooltipPosition="above">
    <mat-icon *ngIf="(skillItem && skillItem[key]||0) === 0" color="var(--primary-color)" [ngStyle]="{'color':'gray'}">star_border
    </mat-icon>
    <mat-icon *ngIf="(skillItem && skillItem[key]||0) === maxValue" [color]="colored ? 'var(--primary-color)' : 'gray'">star
    </mat-icon>
    <mat-icon *ngIf="(skillItem && skillItem[key]||0) > 0 && (skillItem[key]||0) < maxValue"
        [color]="colored ? 'var(--primary-color)' : 'gray'">
        star_half</mat-icon>
      <div *ngIf="skillItem && !hideRatingValue" [ngClass]="skillItem[key] ? 'proficiency-star-value body black' : 'proficiency-star-value body gray' " >{{ (skillItem[key] || 0) }}</div>
      <app-svg-icon *ngIf="showRequiredProficiency&&skillItem.requiredRating&&skillItem.requiredRating>skillItem[key]&&!skillItem.changes"
          class="warning-icon" src="icons/warning.svg" fill="var(--color-hot-pink)"></app-svg-icon>
</button>

<mat-menu #menu class="proficiency-slider-menu" yPosition="above" xPosition="before" >
    <app-proficiency-slider-overlay [key]="key" [editable]="editable" [skillItem]="skillItem||{}" [client]="client" [header]="header"
    [noTooltip]="!editable" (onSetValue)="onRatingSelected($event)">
</app-proficiency-slider-overlay>
</mat-menu>
