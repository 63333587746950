<div class="con">
    <div class="search-con">
        <mat-icon matPrefix class="icon">search</mat-icon>
        <input [placeholder]="opts.placeholder||'Search'" autocomplete="off" class="search" matInput
            [formControl]="searchControl" #search [matAutocomplete]="auto"
            [matAutocompleteDisabled]="opts.searchEndpoint?false:true" (keydown.enter)="onEnter($event)" />
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSearchResultSelected($event)">
            <mat-option *ngFor="let search of searchResults | async" [value]="search">
                {{search.searchDisplayText || search[opts.searchDisplayField]}}
            </mat-option>
        </mat-autocomplete>
        <div class="search-filter" *ngIf="opts.multipleFilter">
            <mat-form-field appearance="outline">
                <mat-select #matSelectRef multiple [disabled]="!secondaryOpts.length" placeholder="Roles"
                    disableOptionCentering [(ngModel)]="selectedValues" (ngModelChange)="onSelectionChange()">
                    <mat-option *ngFor="let role of secondaryOpts" [value]="role">
                        <span class="h4-black">{{role.name}}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <button [matTooltip]="opts.add" mat-stroked-button class="search-button" *ngIf="opts.add"
            (click)="onAddClick()">{{opts.add}}</button>
        <button [matTooltip]="opts.secondaryButton" mat-stroked-button class="secondary-search-button"
            *ngIf="opts.secondaryButton" (click)="onSecondaryButtonClick()">{{opts.secondaryButton}}</button>
    </div>
    <div class="bottom-con">
        <div class="caption gray">{{opts.paginationStr}}</div>
        <div class="search-icons">
            <div style="display: flex; flex-direction: column; justify-content: center; align-items: center">
                <div class="caption gray" *ngIf="opts.showSkillAdjacencyText">Skill Adjacency</div>
                <div style="display: flex; flex-direction: row; justify-content: center; align-items: center">
                    <mat-icon matTooltip="Upload Adj. Skills file" class="right-icon" *ngIf="opts.uploadAdjacentSkill"
                        (click)="uploadAdjacentSkill()">cloud_upload</mat-icon>
                    <mat-icon matTooltip="Download Adj. Skills" class="right-icon" *ngIf="opts.downloadAdjacentSkill"
                        (click)="downloadAdjacentSkill()">cloud_download</mat-icon>
                </div>
            </div>
            <div style="display: flex; flex-direction: column; justify-content: center; align-items: center">
                <div class="caption gray" *ngIf="opts.showSkillAdjacencyText">Skill</div>
                <div style="display: flex; flex-direction: row; justify-content: center; align-items: center">
                    <mat-icon [matTooltip]="'Upload ' + ds.dictionary.specializations.toLowerCase() + 's'"
                        class="right-icon" *ngIf="opts.uploadSpecialization" (click)="onUploadSpecializationClick()">
                        cloud_upload</mat-icon>
                    <mat-icon [matTooltip]="opts.uploadText || 'Upload'" class="right-icon" *ngIf="opts.upload"
                        (click)="onUploadClick()">
                        cloud_upload</mat-icon>
                    <mat-icon [matTooltip]="opts.downloadText || 'Download'" class="right-icon" *ngIf="opts.download"
                        (click)="onDownloadClick()">
                        cloud_download</mat-icon>
                    <mat-icon [matTooltip]="opts.additionalDownloadText || 'Download'" class="right-icon" *ngIf="opts.additionalDownload"
                        (click)="onAdditionalDownloadClick()">
                        cloud_download</mat-icon>
                </div>
            </div>
            <button mat-icon-button class="right-btn" *ngIf="opts.left" [disabled]="opts.leftDisabled"
                (click)="onLeftClick()">
                <mat-icon matTooltip="Previous page" [matTooltipDisabled]="opts.leftDisabled" class="right-icon">
                    keyboard_arrow_left</mat-icon>
            </button>
            <button mat-icon-button class="left-btn" *ngIf="opts.right" [disabled]="opts.rightDisabled"
                (click)="onRightClick()">
                <mat-icon matTooltip="Next page" [matTooltipDisabled]="opts.rightDisabled" class="right-icon">
                    keyboard_arrow_right
                </mat-icon>
            </button>
            <mat-icon class="right-icon" *ngIf="opts.filter" (click)="onFilterClick()">filter_alt</mat-icon>
            <mat-icon class="right-icon add-icon" *ngIf="opts.add" (click)="onAddClick()">{{opts.addIcon||'add'}}
            </mat-icon>
        </div>
    </div>
</div>