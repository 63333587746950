export const IDP_FILTER_KEY = {
  STATUS: "status",
  RECOMMENDED: "RECOMMENDED",
  ASSIGNED: "ASSIGNED",
  ISARCHIVED: "isArchived",
  START_DATE: "startDate",
  END_DATE: "endDate",
  TYPE: "type",
  CATEGORY: "category",
  PROGRESS_STATUS: "progressStatus",
  APPROVAL_STATUS: "approvalStatus",
  DEVELOPMENT_PLAN_ID: "developmentPlanId",
}

export const IDP_GROWTH_PLAN_STATUS = {
  ACTIVE: 'ACTIVE',
  GOAL_SETTING: 'GOAL_SETTING'
}

export const Goal_Chip = {
  GOAL_SETTING : 'goal-setting',
  ACTIVE : 'active'
}

