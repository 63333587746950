<div class="goal-check-in-main-con" [formGroup]="checkInForm">
  <div class="goal-check-in-header d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center gap-1">
      <mat-icon class="goal-checkin-icon fs-16 white">pin_drop</mat-icon>
      <div class="h4 white goal-checkin-title">Check in: {{data?.title}}</div>
    </div>
    <mat-icon class="white cursor-pointer" mat-dialog-close>close</mat-icon>
  </div>
  <div class="p-20">
    <div class="d-flex align-items-center bordered-con bg-blue min-h-0 p-1 mb-2">
      <app-svg-icon class="mr-1 mt-2px" src="icons/info.svg" fill="var(--color-info-blue)"></app-svg-icon>
      <span class="caption">{{ infoTip }}</span>
    </div>
    <div class="mb-20 d-flex align-items-center gap-20">
      <div class="flex-1">
        <div class="body fw-500 mb-1">Goal Progress</div>

        <!-- Incomplete and Complete -->
        <div *ngIf="['role', 'specialization', 'certification'].includes(data.type?.toLowerCase())" class="goal-check-in-status-con px-10 py-1 border-radius-4 w-full">
          <div class="d-flex align-items-center gap-1">
            <div class="py-1 px-3 flex-1 item-center" [ngClass]="{ 'complete-incomplete-con': data.progressStatus !== 'COMPLETED' }">
              <span class="body fw-400">INCOMPLETE</span>
            </div>
            <div class="py-1 px-3 flex-1 item-center" [ngClass]="{ 'complete-incomplete-con': data.progressStatus === 'COMPLETED' }">
              <span class="body fw-400">COMPLETED</span>
            </div>
          </div>
        </div>

        <!-- Percentage -->
        <div *ngIf="data.type?.toLowerCase() === 'course'" class="goal-check-in-status-con p-2 border-radius-4 w-full">
          <div class="d-flex align-items-center gap-12">
              <span class="body fw-400 gray">{{ data?.currentValue * 100 || 0 }}%</span>
              <span class="body fw-400 gray">/</span>
              <span class="body fw-400 gray">100%</span>
          </div>
        </div>

        <!-- Number -->
        <div *ngIf="data.type?.toLowerCase() === 'skill'" class="goal-check-in-status-con p-2 border-radius-4 w-full">
          <div class="d-flex align-items-center gap-12">
              <span class="body fw-400 gray">{{  data?.currentValue || 0 }}</span>
              <span class="body fw-400 gray">/</span>
              <span class="body fw-400 gray">{{  data?.requiredValue || 0 }}</span>
          </div>
        </div>

        <!-- Custom  -->
        <div *ngIf="data.type?.toLowerCase() === 'custom'" class="goal-check-in-status-con border-radius-4 w-full">
            <div *ngIf="data.measurement === 'COMPLETE_INCOMPLETE'" class="d-flex align-items-center px-10 py-1 gap-1">
              <div class="d-flex align-items-center gap-1">
                <div 
                  class="py-1 px-3 flex-1 item-center"
                  [ngClass]="{ 'complete-incomplete-con': selectedStatus !== 'COMPLETED' && data?.progressStatus !== 'COMPLETED' }"
                  (click)="selectStatus('INCOMPLETE')">
                  <span class="body fw-400">INCOMPLETE</span>
                </div>
                <div 
                  class="py-1 px-3 flex-1 item-center"
                  [ngClass]="{ 'complete-incomplete-con': selectedStatus === 'COMPLETED' || data?.progressStatus === 'COMPLETED' }"
                  (click)="selectStatus('COMPLETED')">
                  <span class="body fw-400">COMPLETED</span>
                </div>
              </div>
            </div>

            <div *ngIf="data.measurement !== 'COMPLETE_INCOMPLETE'" class="d-flex align-items-center p-2 gap-12">
              <div (click)="showNumberInput()" class="goal-check-input">
                <!-- <span *ngIf="!showInput" class="body fw-400 gray">
                  {{ data.measurement?.startsWith('CURRENCY') ? (currencyControl?.value || data?.currentValue || 0) + getCurrencySymbol(data.measurement) 
                    : (data.measurement?.startsWith('PERCENTAGE') ? (currencyControl?.value || data?.currentValue || 0) + '%' 
                    : (currencyControl?.value || data?.currentValue || 0)) 
                  }}                
                </span> -->
                <input
                       id="numberInput"
                       type="number"
                       class="complete-incomplete-con number-input"
                       [formControl]="currencyControl"
                       min="0" />
              </div>
              <span class="body fw-400 gray">/</span>
              <span class="body fw-400 gray">
                {{ data.measurement === 'PERCENTAGE' ? '100%' : data.requiredValue || '100' }}
                {{ data.measurement?.startsWith('CURRENCY') ? getCurrencySymbol(data.measurement) : '' }}
              </span>
            </div>
        </div>

      </div>
      <div class="flex-1">
        <div class="body fw-500 mb-1">Status</div>
        <div class="goal-check-in-status-con py-2 px-12 border-radius-4 w-full"
             [ngClass]="{
               'goal-status-completed': data.progressStatus === 'COMPLETED',
               'goal-status-delayed': data.progressStatus === 'DELAYED'
             }">
          <span class="h6">{{data.progressStatus}}</span>
        </div>
      </div>
    </div>
    <div class="body fw-500 mb-1">How is this goal progressing?</div>
    <textarea class="goal-checkin-desc border-radius-4 p-2"
              placeholder="Add context... Are there any accomplishments or risks to share?"
              [formControl]="progressDescriptionControl">
    </textarea>
  </div>
  <div class="px-20 py-3 d-flex align-items-center justify-content-between">
    <button mat-stroked-button class="secondary-button" type="button" mat-dialog-close>Cancel</button>
    <button mat-stroked-button class="primary-button" type="button" (click)="onCheckInClick()">Check In</button>
  </div>
</div>
