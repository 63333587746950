import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { STORAGE_CONSTANTS } from 'src/app/constants/storage.constants';

export interface IPrismSearchPayload {
  payload: any,
  isAdvancedSearch: boolean,
  formattedQuery: string
}

export interface ITagsConfigMap {
  alphanumeric: string[];
  date: string[];
  numeric: string[];
}

@Injectable({
  providedIn: 'root'
})

export class PrismSearchService {

  public tagsConfigMap: ITagsConfigMap = {
    alphanumeric: ['pool-code'],
    date: ['allocation-date'],
    numeric: ['allocation-percentage'],
  };

  private prismSearchPayload = new BehaviorSubject<IPrismSearchPayload | null>(null);
  private existingUserSkills = new ReplaySubject(1);
  private refreshAPI =  new ReplaySubject(1);

  constructor(
    private router: Router
  ) { }

  public navigateToPrismSearch(query: any, isAdvancedSearch = false) {
    let formattedQuery = '';
    if (isAdvancedSearch) {
      formattedQuery = this.formatAdvancedSearchQuery(query);
      localStorage.setItem(STORAGE_CONSTANTS.ADVANCED_SEARCH_STRING_QUERY, formattedQuery);
      this.setPrismSearchPayload({ payload: query, isAdvancedSearch, formattedQuery });
    } else {
      this.setPrismSearchPayload({ payload: query, isAdvancedSearch, formattedQuery: query });
    }
    this.router.navigate(['/sms/prismSearch']);
  }

  public getPrismSearchPayload(): BehaviorSubject<IPrismSearchPayload> {
    return this.prismSearchPayload;
  }

  public setPrismSearchPayload(payload: IPrismSearchPayload | null) {
    this.prismSearchPayload.next(payload);
  }

  public getExistingUserSkills(): ReplaySubject<any>{
    return this.existingUserSkills;
  }

  public setExistingUserSkills(paylod: any) {
    this.existingUserSkills.next(paylod);
  }

  public setRefreshAPI() {
    this.refreshAPI.next();
  }

  public getRefreshAPI() {
    return this.refreshAPI;
  }

  public transformSkillData(skillData: any, settings: any){
    const skillHierarchySettings = settings.client.smsSettings.skillHierarchy;
    skillData.forEach(skill => {
      skill.skillHierarchy = skill.skillHierarchy.map((item) => {
        const hierarchy = skillHierarchySettings.find((setting) => item.order === setting.order);
        return {...item, hierarchyName: hierarchy.name}
      })
      skill.skillHierarchy.sort((a, b) => b.order - a.order);
    });
    return skillData;
  }


  public matchedSearchValue(values: string[]): string {
    const matchedBoldValues = values.slice(0, 3).map(value => {
      return value.replace(/<em>(.*?)<\/em>/g, '<span class="highlights-text">$1</span>');
    });
    const extraHighlightValues = values.slice(3);
    let highlightValue = matchedBoldValues.join(', ');
    if (extraHighlightValues.length > 0) {
      highlightValue += ` ... +${extraHighlightValues.length} more`;
    }

    return highlightValue;
  }

  public formatAdvancedSearchQuery(query: any): string {
    if (!query?.value || !Array.isArray(query.value)) {
      return '';
    }

    return query.value
      .map((condition: any) => {
        if (condition.groupOperator && Array.isArray(condition.value)) {
          return `(${this.formatAdvancedSearchQuery(condition)})`;
        }
        return this.formatCondition(condition);
      })
      .filter(Boolean)
      .join(` ${query.groupOperator.toUpperCase() === 'AND' ? '&' : '|'} `);
  }

  private formatCondition(condition: any): string {
    const entries: any = Object.entries(condition)[0];
    const [field, details] = entries;
    const { operator, value, secondaryValue } = details;

    if (!value || (Array.isArray(value) && value.length === 0)) {
      return '';
    }

    if (field?.includes('date')) {
      return this.formatDateCondition(field, value, operator);
    }

    if (this.tagsConfigMap.numeric.includes(field)) {
      return this.formatNumericCondition(field, value, operator, secondaryValue);
    }

    if (this.tagsConfigMap.alphanumeric.includes(field)) {
      return this.formatDefaultCondition(field, value, operator);
    }

    return this.formatDefaultCondition(field, value, operator);
  }

  private formatNumericCondition(field: string, value: any, operator: string, secondaryValue?: any): string {
    if (operator === 'range') {
      return `${field}: ${value}% to ${secondaryValue}%`;
    }
    return `${field} ${operator} ${value}%`;
  }

  private formatDateCondition(field: string, value: any, operator: string): string {
    const formattedDate = this.formatDate(value);
    return `${field} ${operator} ${formattedDate}`;
  }

  private formatDefaultCondition(field: string, value: any, operator: string): string {
    if (Array.isArray(value) && value.length > 1) {
      return value.map((val: string) => `${field}: "${val}"`).join(' | ');
    }
    return `${field}: "${value}"`;
  }

  private formatDate(dateStr: string): string {
    const date = new Date(dateStr);
    return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
  }
}
